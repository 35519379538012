import { Dispatch, SetStateAction, useState } from "react";
import { useFormContext } from "react-hook-form";
import WysiwygIcon from "@mui/icons-material/Wysiwyg";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import { Box, Button, Pagination } from "@mui/material";

import { ForwardingAdminAttachment } from "@sellernote/_shared/src/types/common/file";
import { WithdrawalFormListData } from "@sellernote/_shared/src/types/forwarding/trello";

import UploadModal from "../../../../../../../../containers/UploadModal";

import DeleteModal from "./DeleteModal";

export default function PreviewController({
  shipmentId,
  bidAccountPayableId,
  invoiceType,
  attachment,
  onAttachmentIndexChange,
  s3Url,
  setImageWidth,
  setPdfScale,
  pdfTotalPage,
  pdfPage,
  setPdfPage,
  fileExtension,
  onOverlayConfirmModalOpen,
  getParsedList,
}: {
  shipmentId: number;
  bidAccountPayableId: number;
  invoiceType: string;
  attachment: ForwardingAdminAttachment;
  onAttachmentIndexChange: (index: number) => void;
  s3Url: string;
  setImageWidth: Dispatch<SetStateAction<number>>;
  setPdfScale: Dispatch<SetStateAction<number>>;
  pdfTotalPage: number;
  pdfPage: number;
  setPdfPage: Dispatch<SetStateAction<number>>;
  fileExtension: string;
  onOverlayConfirmModalOpen: ({ id }: { id: number }) => void;
  getParsedList: (attachmentId: number) => void;
}) {
  const { watch } = useFormContext<WithdrawalFormListData>();
  const withdrawalFormList = watch("withdrawalFormList");

  /** 입력된 매입항목이 있을 때 경고 모달, 없을 때 바로 파싱 요청 */
  const handleReadButtonClick = () => {
    if (withdrawalFormList?.length > 0) {
      onOverlayConfirmModalOpen({ id: attachment.id });
    } else {
      getParsedList(attachment.id);
    }
  };

  const isPdf = (() => {
    return (
      fileExtension === "pdf" ||
      fileExtension === "PDF" ||
      fileExtension === "tiff" ||
      fileExtension === "tif" ||
      fileExtension === "TIF"
    );
  })();

  const [showsUploadModal, setShowsUploadModal] = useState(false);
  const [showsDeleteModal, setShowsDeleteModal] = useState(false);

  const handleModalOpen = () => setShowsUploadModal(true);
  const handleModalClose = () => setShowsUploadModal(false);

  const handleFileDownload = () => (window.location.href = s3Url);

  const handleOpenDeleteModal = () => setShowsDeleteModal(true);

  const handleImageZoom = (zoom: "in" | "out") => {
    setImageWidth((prev) => {
      if (zoom === "out" && prev === 100) return 100;
      return prev + (zoom === "in" ? 10 : -10);
    });
  };

  const handlePdfZoom = (zoom: "in" | "out") => {
    setPdfScale((prev) => {
      if (zoom === "out" && prev === 1.3) return 1.3;
      return prev + (zoom === "in" ? 0.1 : -0.1);
    });
  };

  return (
    <>
      <Box
        display={"flex"}
        alignItems="center"
        justifyContent={"space-between"}
      >
        <Box>
          <Button
            variant="outlined"
            size="small"
            onClick={() => {
              isPdf ? handlePdfZoom("in") : handleImageZoom("in");
            }}
            sx={{ mr: 1 }}
          >
            <ZoomInIcon />
          </Button>

          <Button
            variant="outlined"
            size="small"
            onClick={() => {
              isPdf ? handlePdfZoom("out") : handleImageZoom("out");
            }}
          >
            <ZoomOutIcon />
          </Button>
        </Box>

        {isPdf && (
          <Pagination
            size="small"
            siblingCount={1}
            onChange={(_, page) => setPdfPage(page)}
            page={pdfPage}
            count={pdfTotalPage}
          />
        )}

        <Box>
          <Button
            variant="contained"
            size="small"
            endIcon={<WysiwygIcon />}
            sx={{ mr: 1 }}
            onClick={handleReadButtonClick}
          >
            읽기
          </Button>

          <Button variant="outlined" size="small" onClick={handleFileDownload}>
            다운로드
          </Button>

          <Button
            variant="outlined"
            size="small"
            onClick={handleModalOpen}
            sx={{ mr: 1, ml: 1 }}
          >
            파일 추가
          </Button>

          <Button
            variant="outlined"
            size="small"
            color="error"
            onClick={handleOpenDeleteModal}
          >
            삭제
          </Button>
        </Box>
      </Box>

      <UploadModal
        showsUploadModal={showsUploadModal}
        setShowsUploadModal={handleModalClose}
        type={invoiceType}
        id={shipmentId}
        bidAccountPayableId={bidAccountPayableId}
        dataType={"trello"}
        isPermission={true}
      />

      <DeleteModal
        shipmentId={shipmentId}
        attachmentId={attachment.id}
        onAttachmentIndexChange={onAttachmentIndexChange}
        showsDeleteModal={showsDeleteModal}
        setShowsDeleteModal={setShowsDeleteModal}
      />
    </>
  );
}
