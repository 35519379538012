import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";

import { AccountPaymentCondition } from "@sellernote/_shared/src/types/forwarding/adminAccount";
import { PartnerFinancialAccountInfo } from "@sellernote/_shared/src/types/forwarding/partnerManagement";

import useSnackbar from "../../../../../../../../hooks/useSnackbar";

export default function AccountSelect({
  accountId,
  onAccountIdChange,
  onAccountPaymentChange,
  financialAccounts,
  isWithdrawalRequested,
}: {
  accountId: number | undefined;
  onAccountIdChange: (id: number | null) => void;
  onAccountPaymentChange: (payment: AccountPaymentCondition) => void;
  financialAccounts: PartnerFinancialAccountInfo[];
  isWithdrawalRequested: boolean;
}) {
  const { handleSnackbarOpen } = useSnackbar();

  /** 거래처에 매핑된 파트너가 여러 개일 경우, 파트너명만 노출 */
  const accountOptionList = (() => {
    if (!financialAccounts.length) return [];

    return financialAccounts.map(({ name, id, paymentCondition }) => ({
      name,
      id,
      paymentCondition,
    }));
  })();

  /** 거래처 선택시, ID, 지급조건(월 정산, 일 정산)을 상태에 저장 */
  const handleAccountSelect = (e: SelectChangeEvent<number>) => {
    const selectedId = Number(e.target.value);

    const account = accountOptionList.find(({ id }) => id === selectedId);

    onAccountIdChange(selectedId);

    if (account?.paymentCondition) {
      onAccountPaymentChange(account.paymentCondition);
    }
    handleSnackbarOpen("거래처가 선택되었습니다.");
  };

  return (
    <FormControl size="small" sx={{ width: 250 }}>
      <InputLabel>거래처 선택</InputLabel>

      <Select
        label="거래처 선택"
        value={accountId || 0}
        onChange={handleAccountSelect}
        disabled={isWithdrawalRequested}
      >
        {accountOptionList.map(({ id, name }) => (
          <MenuItem key={id} value={id}>
            {name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
